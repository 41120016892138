const config = {
  query: {
    state: 0,
    themetype: 1,
    circle_id: null,
    collect_id: null,
    topic_id: null,
    user_id: null,
    keywords: null,
    type_id: 0,
    cid: 0,
  },
  picIndex: null,
  // 来源圈子
  showList: [],
  // 合集
  collectList: [],
  // 话题
  recommendList: [],
  // 用户
  userList: [],
  // 按钮列表
  buttonList: Object.freeze([
    {
      label: '通过',
      type: 'primary',
      icon: 'el-icon-circle-check',
      eventName: 'handleTopAdopt',
    },
    {
      label: '不通过',
      type: 'danger',
      icon: 'el-icon-circle-close',
      eventName: 'handleTopFail',
    },
    {
      label: '同步NFT',
      type: 'success',
      icon: 'el-icon-refresh',
      eventName: 'handleSyncNFT',
    },
  ]),
  maxHeight: document.body.clientHeight - 386,
  stateList: Object.freeze([
    {
      id: 0,
      label: '全部',
    },
    {
      id: 1,
      label: '待审核',
    },
    {
      id: 2,
      label: '已通过',
    },
    {
      id: 3,
      label: '未通过',
    },
    {
      id: 4,
      label: '敏感词',
    },
    {
      id: 5,
      label: '用户删除',
    },
    {
      id: 6,
      label: '0回复',
    },
    {
      id: 7,
      label: '0点赞',
    },
    {
      id: 8,
      label: '定时发布',
    },
  ]),
  themeTypeList: Object.freeze([
    {
      id: 1,
      label: '轻分享圈',
    },
    {
      id: 2,
      label: '打卡圈',
    },
  ]),
  cTypeList: [
    {
      value: 1,
      type: 'show',
    },
    {
      value: 2,
      type: 'punch_card',
    },
    {
      value: 3,
      type: 'info',
    },
  ],
  typeList: Object.freeze([
    {
      id: 0,
      label: '全部',
    },
    {
      id: 1,
      label: '精选内容',
    },
    {
      id: 2,
      label: '视频',
    },
    {
      id: 3,
      label: '投票',
    },
    {
      id: 4,
      label: '语音',
    },
    {
      id: 5,
      label: '附件',
    },
    {
      id: 6,
      label: '文章',
    },
  ]),
}

export default config
