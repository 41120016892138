<template>
  <div class="circle-theme-container">
    <SearchForm
      :label-width="80"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :selection="true"
      :table-data="list"
      @handleAdopt="handleAdopt"
      @handleCellClick="handleCellClick"
      @handleCommentEdit="handleCommentEdit"
      @handleCurrentChange="handleCurrentChange"
      @handleFail="handleFail"
      @handleOperationRecord="handleOperationRecord"
      @handlePicDetails="handlePicDetails"
      @handleQuickComments="handleQuickComments"
      @handleQuickLikes="handleQuickLikes"
      @handleSelectionChange="handleSelectionChange"
      @handleSyncNFT="handleSyncNFT"
      @handleTopAdopt="handleTopAdopt"
      @handleTopFail="handleTopFail"
    >
      <!-- 内容 -->
      <template #content="{ row }">
        <div class="circle-theme-user">
          <div class="circle-theme-header">
            <div
              class="circle-theme-wrapper"
              @click.stop="handleUserInfo(row.user_id)"
            >
              <el-image class="circle-theme-head" :src="row.head" />
              <el-image
                v-if="row.cert_pic_url"
                class="circle-theme-cert"
                :src="row.cert_pic_url"
              />
            </div>
            <div class="circle-theme-info">
              <span v-if="row.delete == 2" class="circle-theme-delete">
                {{ translateTitle('用户已删除') }}
              </span>
              <span
                class="circle-theme-name"
                @click.stop="handleUserInfo(row.user_id)"
              >
                {{ row.name }}
              </span>
              <img
                v-if="row.group_ico_src"
                alt=""
                class="circle-theme-level"
                :src="row.group_ico_src"
              />
              <img
                v-if="row.medal_pic_url"
                alt=""
                class="circle-theme-level"
                :src="row.medal_pic_url"
              />
              <span class="circle-theme-time">
                {{ row.pubdate_str }} ({{ row.whole_create_time_str }})
              </span>
              <span
                v-if="row.extra.role_banned === -1"
                class="circle-theme-banner"
                @click.stop="handleMute(row, null, translateTitle('禁言'))"
              >
                {{ translateTitle('禁言') }}
              </span>
              <span
                v-if="row.extra.role_banned === 1"
                class="circle-theme-cancel-banner"
                @click.stop="
                  handleCancelMute(row, null, translateTitle('取消禁言'))
                "
              >
                {{ translateTitle('取消禁言') }}
              </span>
            </div>
          </div>
          <div
            class="circle-theme-content"
            @click.stop="handleThemeDetail(row)"
            v-html="row.content"
          ></div>
          <div v-if="row.pics && !row.video_url" class="circle-theme-pics">
            <div
              v-for="(pic, index) in row.pics_arr"
              :key="pic.id"
              class="circle-theme-pics-content"
              @mouseenter="mouseOver(index)"
              @mouseleave="mouseLeave()"
            >
              <el-image
                class="circle-theme-item-pic"
                :preview-src-list="[pic.url]"
                :src="pic.url"
              />
              <span
                v-if="picIndex === index && row.pics_arr.length > 1"
                @click.stop="handleSetCoverPic(row, pic)"
              >
                设为封面
              </span>
            </div>
          </div>
          <div
            v-if="row.pics && row.video_url"
            class="circle-theme-pics"
            @click.stop="handleVideo(row.video_url)"
          >
            <el-image
              v-for="pic in row.pics_arr"
              :key="pic.id"
              class="circle-theme-item-pic"
              :src="pic.url"
            />
            <span class="el-icon-video-play"></span>
          </div>
          <div class="circle-theme-item-info">
            <span v-if="row.circle_name" class="circle-name">
              {{ row.circle_name }}
            </span>
            <span>ID: {{ row.id }}</span>
            <span>{{ translateTitle('阅读') }}: {{ row.click || 0 }}</span>
            <span>{{ translateTitle('评论') }}: {{ row.comments || 0 }}</span>
            <span>{{ translateTitle('点赞') }}: {{ row.applauds || 0 }}</span>
          </div>
        </div>
      </template>
      <!-- 其他信息 -->
      <template #other="{ row }">
        <div class="log">
          <span>{{ row.client_info }}</span>
        </div>
        <el-tag v-if="row.audit == 2" type="success">
          {{ translateTitle('通过') }}
        </el-tag>
        <el-tag v-if="row.audit == 1" type="warning">
          {{ translateTitle('待审核') }}
        </el-tag>
        <el-tag v-if="row.audit == 3" type="danger">
          {{ translateTitle('未通过') }}
        </el-tag>
        <!-- <div v-if="row.log" class="log">
          <span>{{ row.log.action }}</span>
          <span>{{ row.log.create_time_str }}</span>
          <span>{{ row.log.user.name }}</span>
        </div> -->
      </template>
      <!-- 快捷评论 -->
      <template #quickcomments="{ row, el }">
        <span>
          {{ translateTitle(el.label) }} ({{ row.quick_comment_count }})
        </span>
      </template>
      <!-- 随机点赞 -->
      <template #quicklikes="{ row, el }">
        <span>{{ translateTitle(el.label) }} ({{ row.applauds_count }})</span>
      </template>
    </TablePaging>
    <!-- 用户信息 -->
    <UserInfoDialog
      ref="userInfoDialog"
      @getTableData="getTableData"
      @handleCancelMute="handleCancelMute"
    />
    <!-- 审批不通过 -->
    <AuditNotPassDialog
      ref="auditNotPass"
      @handleAuditNotPass="handleAuditNotPass"
    />
    <!-- 同步NFT对话框 -->
    <VestList ref="vestList" @setUserInfo="handleSync" />
    <!-- 禁言-对话框 -->
    <MuteDialog ref="muteDialog" @getTableData="getTableData" />
    <!-- 操作记录 -->
    <OperationRecord ref="operationRecord" :themetype="query.themetype" />
    <!-- 评论编辑 -->
    <CommentEdit ref="commentEdit" @getTableData="getTableData" />
    <!-- 图片详情 -->
    <PicDetails ref="picDetails" />
    <!-- 快捷评论 -->
    <QuickComments ref="quickComments" @getTableData="getTableData" />
  </div>
</template>

<script>
  import config from './config'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import UserInfoDialog from '../../user/personList/components/userInfoDialog'
  import MuteDialog from '../../user/personList/components/muteDialog'
  import AuditNotPassDialog from '@/components/AuditNotPassDialog'
  import OperationRecord from '../components/operationRecord'
  import CommentEdit from '../components/commentEdit'
  import QuickComments from '../components/quickComments'
  import VestList from '../components/vestList'
  import PicDetails from '../components/picDetails'
  import {
    getAuditThemeList,
    passOrNotTheme,
    getCollectList,
    getRecommendList,
    getUserList,
    setCoverPic,
    randomApplaudTheme,
    createNFT,
  } from '@/api/audit'
  import { getShowList } from '@/api/information'
  import { cancleUserMute, cancleBatchUserBanned } from '@/api/user'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      SearchForm,
      TablePaging,
      AuditNotPassDialog,
      OperationRecord,
      CommentEdit,
      PicDetails,
      UserInfoDialog,
      MuteDialog,
      QuickComments,
      VestList,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      searchColumn() {
        return [
          {
            label: '状态',
            prop: 'state',
            type: 'select',
            clearable: false,
            selectList: this.stateList,
          },
          {
            label: '主题类型',
            prop: 'themetype',
            type: 'select',
            clearable: false,
            selectList: this.themeTypeList,
          },
          {
            label: '圈子',
            prop: 'circle_id',
            type: 'select',
            clearable: false,
            selectList: this.showList,
            optionLabel: 'name',
          },
          {
            label: '话题',
            prop: 'topic_id',
            type: 'select',
            clearable: false,
            selectList: this.recommendList,
            optionLabel: 'name',
          },
          {
            label: '合集',
            prop: 'collect_id',
            type: 'select',
            clearable: false,
            selectList: this.collectList,
            optionLabel: 'name',
          },
          {
            label: '类型',
            prop: 'type_id',
            type: 'select',
            clearable: false,
            selectList: this.typeList,
          },
          {
            label: '用户',
            prop: 'user_id',
            type: 'select',
            selectList: this.userList,
            optionLabel: 'name',
            optionValue: 'user_id',
            filterable: true,
          },
          {
            label: '关键字',
            prop: 'keywords',
          },
        ]
      },
      // 表格配置
      columnList() {
        return [
          { label: '内容', slotName: 'content', align: 'left' },
          { label: '其他信息', slotName: 'other', width: 200 },
          {
            label: '操作',
            isButton: true,
            btnList: [
              {
                label: '通过',
                type: 'primary',
                eventName: 'handleAdopt',
                showName: 'audit',
                showStatusList: [1, 3],
              },
              {
                label: '不通过',
                type: 'danger',
                eventName: 'handleFail',
                showName: 'audit',
                showStatusList: [1, 2],
              },
              {
                label: '快捷评论',
                type: 'primary',
                eventName: 'handleQuickComments',
                soltName: 'quickcomments',
              },
              {
                label: '随机点赞',
                type: 'primary',
                eventName: 'handleQuickLikes',
                soltName: 'quicklikes',
              },
              // {
              //   label: '操作记录',
              //   type: 'primary',
              //   eventName: 'handleOperationRecord',
              // },
              // {
              //   label: '查看详情',
              //   type: 'primary',
              //   eventName: 'handleConfig',
              // },
              {
                label: '图片详情',
                type: 'primary',
                eventName: 'handlePicDetails',
                showStatusList: this.setShow(),
              },
              {
                label: '删除',
                type: 'danger',
                eventName: 'handleConfig',
                isHide: this.$route.query.state === 3,
              },
            ],
          },
        ]
      },
    },
    watch: {
      'query.state': {
        handler(newVal) {
          this.pageInfo.page = 1
          localStorage.setItem('state1', newVal)
          this.getTableData()
        },
      },
      'query.themetype': {
        handler(newVal) {
          this.pageInfo.page = 1
          localStorage.setItem('themeType1', newVal)
          this.getTableData()
        },
      },
    },
    created() {
      if (this.$route.query.themeType) {
        this.query.themetype = Number(this.$route.query.themeType)
      } else {
        if (localStorage.getItem('themeType1')) {
          this.query.themetype = Number(localStorage.getItem('themeType1'))
        } else {
          this.query.themetype = 1
        }
      }

      if (this.$route.query.state) {
        this.query.state = Number(this.$route.query.state)
      } else {
        if (localStorage.getItem('state1')) {
          this.query.state = Number(localStorage.getItem('state1'))
        } else {
          this.query.state = 0
        }
      }

      if (this.$route.query.cid) this.query.cid = this.$route.query.cid

      if (this.$route.query.content_id)
        this.query.content_id = this.$route.query.content_id
      this.getShowList()
      this.getCollectList()
      this.getRecommendList()
      this.getUserList()
      this.getTableData()
    },
    methods: {
      translateTitle,
      setShow() {
        let arr = []
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i]) {
            if (this.list[i].pics_count > 0 && !this.list[i].video_url) {
              arr.push(i)
            }
          }
        }
        return arr
      },
      // 获取来源圈子
      async getShowList() {
        const res = await getShowList()
        if (res.success) this.showList = res.list || []
      },
      // 获取合集
      async getCollectList() {
        const res = await getCollectList()
        if (res.success) this.collectList = res.list || []
      },
      // 获取话题
      async getRecommendList() {
        const res = await getRecommendList()
        if (res.success) this.recommendList = res.list || []
      },
      // 获取用户列表
      async getUserList() {
        const res = await getUserList()
        if (res.success) this.userList = res.list || []
      },
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getAuditThemeList(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
      // 获取个人信息
      handleUserInfo(id) {
        this.$refs.userInfoDialog.handleOpen(id)
      },
      // 通过
      handleAdopt(val) {
        const params = {
          content_id: val.id,
          themetype: 1,
          audit: 2,
          remark: '',
          is_push: false,
        }
        this.passOrNotTheme(params)
      },
      // 批量通过
      handleTopAdopt() {
        if (this.selectionList.length === 0) {
          return this.$message.error('请至少选择一条数据进行操作')
        }
        const params = {
          content_id: this.selectionList.map((item) => item.id).join(),
          themetype: 1,
          audit: 2,
          remark: '',
          is_push: false,
        }
        this.passOrNotTheme(params)
      },
      // 批量不通过弹框
      handleTopFail() {
        if (this.selectionList.length === 0) {
          return this.$message.error('请至少选择一条数据进行操作')
        }
        const val = {
          id: this.selectionList.map((item) => item.id).join(),
        }
        this.$refs.auditNotPass.handleOpen(val)
      },
      // 通过不通过状态更新
      async passOrNotTheme(params) {
        const res = await passOrNotTheme(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `更新状态成功` : `更新状态失败`,
        })
        if (res.success) this.getTableData()
      },
      // 禁言/禁止私聊
      handleMute(row, index, buttonName) {
        this.$refs.muteDialog.handleOpen(buttonName, row)
      },
      // 取消禁言/恢复私聊
      handleCancelMute(row, index, buttonName) {
        const params = { id: row.user_id }
        this.$confirm(`此操作将${buttonName}当前这条数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res
            if (buttonName === '取消禁言') {
              res = await cancleUserMute(params)
            } else {
              res = await cancleBatchUserBanned(params)
            }
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 不通过按钮
      handleFail(val) {
        this.$refs.auditNotPass.handleOpen(val)
      },
      // 不通过弹窗
      handleAuditNotPass(val, query) {
        const params = {
          content_id: val.id,
          themetype: 1,
          audit: 3,
          remark: query.remark,
          is_push: query.is_push,
        }
        this.passOrNotTheme(params)
      },
      // 设为封面
      async handleSetCoverPic(row, pic) {
        const params = {
          content_id: row.id,
          themetype: 1,
          cover_pic: pic.url,
        }
        const res = await setCoverPic(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `设置成功` : `设置失败`,
        })
        if (res.success) this.getTableData()
      },
      // 快捷评论
      handleQuickComments(val) {
        this.$refs.quickComments.handleOpen(val)
      },
      // 随机点赞
      async handleQuickLikes(val) {
        const params = {
          themetype: 1,
          theme_id: val.id,
        }
        const res = await randomApplaudTheme(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `点赞成功` : `点赞失败`,
        })
        if (res.success) this.getTableData()
      },
      // 跳转详情
      handleThemeDetail(row) {
        this.$router.push({
          path: '/audit/circleDetails',
          query: {
            type: 2,
            content_id: row.id,
            circle_id: row.circle_id,
          },
        })
      },
      handleCellClick(row, column) {
        if (column.label === '内容') this.handleThemeDetail(row)
      },
      // 操作记录
      handleOperationRecord(val) {
        this.$refs.operationRecord.handleOpen(val)
      },
      // 评论编辑
      handleCommentEdit(val) {
        this.$refs.commentEdit.handleOpen(val)
      },
      // 图片详情
      handlePicDetails(val) {
        this.$refs.picDetails.handleOpen(val)
      },
      // 同步NFT弹框
      handleSyncNFT() {
        if (this.selectionList.length === 0) {
          return this.$message.error('请至少选择一条数据进行操作')
        }
        this.$refs.vestList.handleOpen('选择作品发布用户')
      },
      // 同步NFT
      async handleSync(val) {
        const params = {
          user_id: val.user_id,
          works: this.selectionList.map((item) => {
            return {
              id: item.id,
              pic_url: item?.pics_arr[0]?.url || '',
            }
          }),
        }
        const res = await createNFT(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.msg,
        })
        if (res.success) this.getTableData()
      },
      // 视频地址打开
      handleVideo(url) {
        window.open(url)
      },
      // 鼠标移入
      mouseOver(index) {
        this.picIndex = index
      },
      // 鼠标移出
      mouseLeave() {
        this.picIndex = null
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.circle-theme';
  #{$base}-user {
    #{$base}-header {
      display: flex;
      align-items: center;
      #{$base}-wrapper {
        position: relative;
        margin-right: 10px;
        cursor: pointer;
        #{$base}-head {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        #{$base}-cert {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 16px;
        }
      }
      #{$base}-info {
        font-size: 13px;
        #{$base}-level {
          height: 16px;
          margin: 0 3px;
          vertical-align: sub;
        }
        #{$base}-time {
          color: $base-color-text-secondary;
        }
        #{$base}-banner {
          color: $base-color-red;
          cursor: pointer;
        }
        #{$base}-cancel-banner {
          color: $base-color-yellow;
          cursor: pointer;
        }
      }
      #{$base}-reply-info {
        color: $base-color-text-secondary;
      }
    }
    #{$base}-content {
      text-align: left;
      cursor: pointer;
      &:hover {
        color: $base-color-blue;
        text-decoration: underline;
      }
    }
    #{$base}-pics {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      #{$base}-item-pic {
        width: 120px !important;
        height: 120px !important;
      }
      #{$base}-pics-content {
        position: relative;
        margin-right: 8px;
        margin-bottom: 8px;
        span {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 20px;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.8);
        }
        img {
          display: block;
        }
      }
      .el-icon-video-play {
        position: absolute;
        top: 50%;
        left: 60px;
        margin-top: -25px;
        margin-left: -25px;
        font-size: 50px;
        color: #fff;
        cursor: pointer;
      }
    }
    #{$base}-item-info {
      font-size: 14px;
      color: $base-color-text-secondary;
      span {
        margin-right: 10px;
      }
      .circle-name {
        padding: 4px;
        // cursor: pointer;
        font-size: 12px;
        line-height: 1;
        background-color: #eee;
      }
    }
  }
  .log {
    span {
      margin: 0 5px;
    }
  }
</style>
